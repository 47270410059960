<template>
  <b-card
    class="mb-2"
    no-body
  >
    <div class="custom-search d-flex justify-content-end p-1">
      <b-button
        variant="primary"
        @click="handleAdd()"
      >
        <feather-icon
          v-if="!isBusy"
          icon="PlusIcon"
          size="21"
          class="text-body text-primary align-middle mr-1 "
          @click="handleAdd"
        />
        Add New
      </b-button>
    </div>
    <b-editable-table
      v-model="items"
      disable-default-edit
      :row-update="rowUpdate"
      :edit-mode="'row'"
      bordered
      class="editable-table w-100"
      :fields="fields"
      :busy="isBusy"
    >
      <template #cell(updatedTime)="data">
        <span v-if="data.value">{{ msEpochToDate(data.value) }}</span>
      </template>
      <template #cell(edit)="data">
        <div v-if="data.isEdit">
          <BIconX
            class="edit-icon"
            @click="handleSubmit(data, false)"
          />
          <BIconCheck
            class="edit-icon"
            @click="handleSubmit(data, true)"
          />
        </div>
        <BIconPencil
          v-else
          class="edit-icon"
          @click="handleEdit(data, true)"
        />
      </template>
      <template #cell(delete)="data">
        <BIconTrash
          class="remove-icon"
          @click="handleDelete(data)"
        />
      </template>
    </b-editable-table>
  </b-card>
</template>

<script>
import {
  BIconTrash,
  BIconPencil,
  BIconX,
  BIconCheck,
  BButton,
  BCard,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted, watch } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import BEditableTable from 'bootstrap-vue-editable-table'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import { msEpochToDate } from '@core/utils/filter'
import faultCodeStore from './faultCodeStore'

export default {
  components: {
    BEditableTable,
    BIconX,
    BIconTrash,
    BIconPencil,
    BIconCheck,
    BButton,
    BCard,
  },
  setup() {
    const toast = useToast()
    const fields = [
      {
        key: 'faultCode',
        label: 'Code',
        sortable: true,
        editable: true,
        type: 'number',
        min: 1000,
        max: 9999,
        class: 'name-col',
      },
      {
        key: 'faultDescription', label: 'Description', sortable: true, type: 'text', editable: true, class: 'name-col',
      },
      {
        key: 'faultCategory', label: 'Category', sortable: true, type: 'text', editable: true, class: 'name-col',
      },
      {
        key: 'faultOrigin', label: 'Origin', sortable: true, type: 'text', editable: true, class: 'name-col',
      },
      {
        key: 'enabled', label: 'Active', sortable: true, type: 'checkbox', editable: true, class: 'is-active-col',
      },
      {
        key: 'ttlValue', label: 'TTL', sortable: true, type: 'number', editable: true, class: 'is-active-col',
      },
      {
        key: 'updatedBy', label: 'Updated By', sortable: true, editable: false,
      },
      {
        key: 'updatedTime', label: 'Updated Time', sortable: true, editable: false,
      },
      { key: 'edit', label: 'Edit' },
      { key: 'delete', label: 'Delete' },
    ]
    const items = ref([])
    const rowUpdate = ref({})
    const isBusy = ref(false)
    const USER_APP_STORE_MODULE_NAME = 'fault-code-list'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, faultCodeStore)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const getFaultCodes = () => {
      isBusy.value = true
      store.dispatch(`${USER_APP_STORE_MODULE_NAME}/fetchFaultCodes`)
        .then(response => {
          const { data } = response
          items.value = data
          rowUpdate.value = {}
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching fault codes',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }
    getFaultCodes()

    const handleAdd = () => {
      const newId = Date.now()
      rowUpdate.value = {
        edit: true,
        id: newId,
        action: 'add',
        data: {
          id: newId,
          faultCode: items.value.map(item => item.faultCode).reduce((a, b) => Math.max(a, b)) + 1,
          faultCategory: '',
          faultOrigin: '',
          faultDescription: '',
          enabled: true,
          updatedBy: '',
          updatedTime: 0,
          ttlValue: 0,
          isNew: true,
        },
      }
    }

    const handleEdit = data => {
      rowUpdate.value = { edit: true, id: data.id }
    }
    const handleDelete = data => {
      rowUpdate.value = { id: data.id, action: 'delete' }
      store.dispatch(`${USER_APP_STORE_MODULE_NAME}/deleteFaultCode`, data.id)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Fault Code Deleted',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          getFaultCodes()
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error deleting fault code',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    const addFaultCode = data => {
      store.dispatch(`${USER_APP_STORE_MODULE_NAME}/addFaultCode`, data)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Fault Code Added',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          getFaultCodes()
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding fault code',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const updateFaultCode = data => {
      store.dispatch(`${USER_APP_STORE_MODULE_NAME}/updateFaultCode`, data)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Fault Code Updated',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          getFaultCodes()
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating fault code',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    const handleSubmit = (data, update) => {
      rowUpdate.value = {
        edit: false,
        id: data.id,
        action: update ? 'update' : 'cancel',
        data: data.item,
      }
    }

    watch(items, () => {
      if (rowUpdate.value) {
        if (rowUpdate.value.action === 'update') {
          if (rowUpdate.value.data.isNew) {
            addFaultCode(rowUpdate.value.data)
          } else {
            updateFaultCode(rowUpdate.value.data)
          }
        }
      }
    })

    return {
      fields,
      items,
      rowUpdate,
      isBusy,
      handleAdd,
      handleSubmit,
      handleEdit,
      handleDelete,
      msEpochToDate,

    }
  },

}
</script>

  <style>
  .table-container {
    margin: 10px;
  }

  table.editable-table {
    margin-top: 10px;
  }

  table.editable-table td {
    vertical-align: middle;
  }

  .editable-table .data-cell {
    padding: 8px;
    vertical-align: middle;
  }

  .editable-table .custom-checkbox {
    width: 50px;
  }

  .remove-icon {
    color: red;
    cursor: pointer;
    font-size: 20px;
  }

  .edit-icon {
    color: rgb(4, 83, 158);
    cursor: pointer;
    font-size: 20px;
  }

  </style>
