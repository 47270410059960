import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchFaultCodes() {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/faultcodes')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFaultCode(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/faultcodes/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFaultCode(ctx, faultCodeData) {
      return new Promise((resolve, reject) => {
        axios
          .put('/user/faultcodes', faultCodeData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateFaultCode(ctx, faultCodeData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/user/faultcodes/${faultCodeData.id}`, faultCodeData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteFaultCode(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/user/faultcodes/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
